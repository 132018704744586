<template>
  <div class="agree-page">
    <div class="agree_main">
      <div class="agree_pos">
        <router-link to="/personage/agreeCheck">我的上传 </router-link>
        <span> > </span>
        <router-link class="agree_a" to="">合同详情</router-link>
      </div>
      <!--agree-body start-->
      <div class="record-body">
        <ul class="personage_ul">
          <li>
            <div class="personage_ul_img m-r-20">
              <img
                :src="details.fang && details.fang.fang_fengmian_image"
                alt=""
              />
              <div class="personage_img_marking">{{ details.fang && details.fang.fang_level }}</div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="personage_ul_name">{{  details.fang && details.fang.name }}</div>
              <div class="personage_ul_title">
                {{  details.fang && details.fang.fang_xing_copy }} | {{  details.fang && details.fang.fang_mianji }}m²
              </div>
              <div class="personage_ul_tags" v-if=" details.fang">
                <span class="tag" v-for="(item,index) in details.fang.fang_biaoqian" :key="index">{{ item }}</span>
              </div>
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <div class="personage_ul_price_left m-r-20">
                    <span>{{  details.fang && details.fang.can_price }}元/月</span>
                    <!-- <img src="../../assets/image/personage/up2.png" alt="" /> -->
                  </div>
                  <!-- <div class="personage_ul_price_right">
                    <span>{{  details.fang && details.fang.zong_price }}元/m²</span>
                    <img src="../../assets/image/personage/up1.png" alt="" />
                  </div> -->
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--agree_detail start-->
        <div class="agree_detail">
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">客户信息</div>
            <div class="agree_detail_item">
              <div class="li w33">
                <span class="color-6">客户姓名：</span> {{ details.kehu_name }}
              </div>
              <div class="li w33">
                <span class="color-6"> 电话：</span>{{ details.kehu_mobile }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">业主信息</div>
            <div class="agree_detail_item">
              <div class="li w33">
                <span class="color-6">业主姓名：</span>{{  details.fang && details.fang.yezhu_name }}
              </div>
              <div class="li w33">
                <span class="color-6"> 电话：</span>{{  details.fang && details.fang.yezhu_mobile }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">其他信息</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">签约人：</span>{{ details.user_name }}
              </div>
              <div class="li w50">
                <span class="color-6"> 工号：</span>{{ details.uid }}
              </div>
              <div class="li w50">
                <span class="color-6">合同起始时间：</span>{{ details.start_time }}
              </div>
              <div class="li w50">
                <span class="color-6"> 合同到期时间：</span>{{ details.end_time }}
              </div>
              <div class="li w50">
                <span class="color-6">打款账号：</span>{{ details.account }}
              </div>
              <div class="li w50">
                <span class="color-6"> 成交价格：</span>{{ details.price }}元
              </div>
              <div class="li">
                <span class="color-6">备注：</span
                >{{ details.text }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">合同信息</div>
            <div class="agree_detail_item">
              <img class="img" :src="details.contract_image" />
            </div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">合同领取时间：</span>{{ details.contract_start_time }}
              </div>
              <div class="li w50">
                <span class="color-6">合同归还时间：</span>{{ details.guihuan_time }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">打款凭证</div>
            <div class="agree_detail_item">
              <img class="img" :src="details.shoiuju" />
            </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">成交报告</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">合同编号：</span>{{ details.number }}
              </div>
              <div class="li w50">
                <span class="color-6">店铺：</span>{{ details.baogao && details.baogao.dian_name }}
              </div>
              <div class="li w50">
                <span class="color-6">成交价格：</span>{{ details.baogao && details.baogao.price }}元
              </div>
              <div class="li w50">
                <span class="color-6">收佣：</span>{{ details.baogao && details.baogao.yong_price }}元
              </div>
              <!-- <div class="li w50">
                <span class="color-6">组别：</span>张美玉
              </div> -->
              <div class="li w50">
                <span class="color-6">备注：</span
                >{{ details.baogao && details.baogao.beizhu }}
              </div>
              <div class="li w50">
                <span class="color-6">资料是否齐全：</span>{{ details.baogao && details.baogao.ziliao }}
              </div>
            </div>
            <div v-if="details.baogao">
            <div class="agree_detail_item">
              <img class="img" v-for="(item,index) in details.baogao.ziliao_image" :key="index" :src="item" />
            </div>
            <div class="agree_detail_item" v-for="(item,index) in details.baogao.chengjiao" :key="index + 10">
              <div class="li w33">
                <span class="color-6">成交方：</span>{{ item.user_name }}
              </div>
              <div class="li w33"><span class="color-6">比例：</span>{{ item.bi }}%</div>
              <div class="li w33">
                <span class="color-6">成交方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item,index) in details.baogao.xinxi" :key="index + 20">
              <div class="li w33">
                <span class="color-6">信息方：</span>{{ item.user_name }}
              </div>
              <div class="li w33"><span class="color-6">比例：</span>{{ item.bi }}%</div>
              <div class="li w33">
                <span class="color-6">成交方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item,index) in details.baogao.weihu" :key="index + 30">
              <div class="li w33">
                <span class="color-6">维护方：</span>{{ item.user_name }}
              </div>
              <div class="li w33"><span class="color-6">比例：</span>{{ item.bi }}%</div>
              <div class="li w33">
                <span class="color-6">成交方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item,index) in details.baogao.img" :key="index + 40">
              <div class="li w33">
                <span class="color-6">图片方：</span>{{ item.user_name }}
              </div>
              <div class="li w33"><span class="color-6">比例：</span>{{ item.bi }}%</div>
              <div class="li w33">
                <span class="color-6">成交方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item,index) in details.baogao.yaoshi" :key="index + 50">
              <div class="li w33">
                <span class="color-6">钥匙方：</span>{{ item.user_name }}
              </div>
              <div class="li w33"><span class="color-6">比例：</span>{{ item.bi }}%</div>
              <div class="li w33">
                <span class="color-6">成交方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item,index) in details.baogao.weituo" :key="index + 60">
              <div class="li w33">
                <span class="color-6">委托方：</span>{{ item.user_name }}
              </div>
              <div class="li w33"><span class="color-6">比例：</span>{{ item.bi }}%</div>
              <div class="li w33">
                <span class="color-6">成交方业绩：</span>{{ item.price }}元
              </div>
            </div>
            <div class="agree_detail_item" v-for="(item,index) in details.baogao.qita" :key="index + 70">
              <div class="li w33">
                <span class="color-6">其他方：</span>{{ item.user_name }}
              </div>
              <div class="li w33"><span class="color-6">比例：</span>{{ item.bi }}%</div>
              <div class="li w33">
                <span class="color-6">成交方业绩：</span>{{ item.price }}元
              </div>
            </div>
          </div>
          </div>
          <!--agree_detail_box end-->
          <!--agree_detail_box start-->
          <div class="agree_detail_box">
            <div class="agree_detail_tit">订单信息</div>
            <div class="agree_detail_item">
              <div class="li w50">
                <span class="color-6">发起时间：</span>{{ details.createtime }}
              </div>
              <div class="li w50">
                <span class="color-6"> 签约时间：</span>{{ details.updatetime }}
              </div>
            </div>
          </div>
          <!--agree_detail_box end-->
        </div>
        <!--agree_detail end-->
      </div>
    </div>
    <!-- 合同详情弹出 S -->
    <!-- <agree-detail-pop ref="detailPop"></agree-detail-pop> -->
    <!-- 合同详情弹出 E -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      details: {}, //详情
      from:{
        id:'',
        fang_type:''
      },

    };
  },
  methods:{
    //获取详情
    getDetails () {
      this.$axios.contractDetails(this.from).then(res=>{
        this.details = res.data
      })
    }
  },
  created () {
    this.from.id = this.$route.query.id
    this.from.fang_type = this.$route.query.type
    this.getDetails()
  }
};
</script>

<style scoped lang="less">
.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
  .select {
    width: 200px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.agree_a {
  color: #3273f6;
  cursor: pointer;
}
/deep/.key_page_select .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
}
.agree_main {
  background: #fff;
  padding-bottom: 30px;
}
.agree-head {
  margin: 0 20px;
  padding: 20px 0px;
  border-bottom: 1px solid #eaeaea;
}
.agree_pos {
  padding: 30px 30px;
  color: #666;
}
.agree_detail {
  background: #f8fafe;
  margin: 0 50px;
  padding: 30px;
  .agree_detail_tit {
    font-size: 18px;
    position: relative;
    padding-left: 10px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .agree_detail_tit:before {
    width: 4px;
    height: 20px;
    background: #3273f6;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -10px;
    display: block;
    content: "";
  }
  .agree_detail_item {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    padding-left: 10px;
  }
  .w33 {
    width: 30%;
  }
  .w50 {
    width: 40%;
  }
  .li {
    line-height: 30px;
  }
  .agree_detail_box {
    margin: 25px 0px;
  }
  .img {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
}
.personage_ul_tags {
  display: flex;
  margin: 0 -5px;
  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}
.personage_ul {
  li {
    padding: 0px 50px 30px 50px;
    display: flex;
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
